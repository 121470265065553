.App {
  text-align: center;
}


.ul {
  background-color: #c6cbd077;
  width: 50% ;
  align-items: center;
  padding: 30px;
  padding-bottom: 80px;
  margin: 20px auto;
  margin-left: 25% auto ;
  

}

.banner {
  background-image: url("/public/assets/bg-img3.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  color: #0b1931;
  text-align: center;
}

.banner h1 {
  font-size: 50px;
  font-weight: 400;
}

.banner h3 {
  font-size: 325x;
  font-weight: 400;
}

.banner-btns{
  color: #80b1ff;

}

.about {
  padding: 30px;
  text-align: center;
  padding-bottom: 80px;
}

.about h1,
h3 {
  font-weight: 200;
}

.about h3 {
  width: 60%;
  margin: 30px auto;
}

.about-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.about-card {
  border: 1px solid #00000062;
  padding: 20px;
  text-align: center;
  max-width: 300px;
  font-weight: 200;
}

.about-card h2 {
  font-weight: 200;
}

.about-icon {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #80b1ff;
  margin: 0 auto;
}

.about-icon img {
  height: 50px;
  width: 50px;
}

.service {
  background-color: #ffffff;
  padding: 50px 30px;
  text-align: center;
  color: #80b1ff;
}

.service h2,
h4 {
  font-weight: 200;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  margin-bottom: 40px;
}

.service-card {
  max-width: 300px;
  color: #0a1931;
  font-weight: 200;
  text-align: center;
  
}

.h2{
  height: 57px;
}

.service-p {
  height: 150px;
}
.service-img {
  max-width: 300px;
  max-height: 185px;
  
}


.service-card h2 {
  font-weight: 200;
}

.footer-wrapper {
  background-color: #f3f5f777;
  padding: 20px 0;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 70%;
  margin: 0 auto;
}

.footer-section {
  width: 200px;
  font-weight: 200;
}

.footer-social {
  width: 40px;
  gap: 15px;
  display: inline-block; 
  max-width: 80%;
}

.footer-social img {
  width: 65%;
  
}

.hidden {
  display: none; 
}

.footer-link {
  padding-bottom: 10px;
  border-bottom: 1px solid #0000003b;
}

@media screen and (max-width: 768px) {
  .footer,
  .about h3 {
    width: 95%;
  }

  .about,
  .service {
    padding: 50px 15px;
  }

  .about-card,
  .service-card .footer-section {
    max-width: 100%;
  }
}
